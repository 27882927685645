
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function SearchBoxRT () {
    return _createElement('form', {
        'className': 'search__predictive__form',
        'onSubmit': this.preventFormSubmission
    }, _createElement('div', { 'className': 'cm_search-box_form-container' }, [
        [this.searchInput(function () {
                return _createElement('div', {
                    'type': 'search',
                    'data-predictive-search-input': true,
                    'aria-label': 'Search our store',
                    'className': 'cm_searchInput'
                }, '\n  What can we help you find?\n');
            }, {
                widgetName: 'undefined',
                items: undefined
            })],
        _createElement('button', {
            'className': 'cm_search-box_button search__predictive__form__button',
            'data-cm-role': 'add-query',
            'key': '1302'
        }, [_createElement('svg', {
                'width': '24',
                'height': '24',
                'viewBox': '0 0 24 24',
                'fill': 'none',
                'xmlns': 'http://www.w3.org/2000/svg',
                'key': '6340'
            }, _createElement('path', {
                'd': 'M10.875 18.7452C15.2242 18.7452 18.75 15.2195 18.75 10.8702C18.75 6.521 15.2242 2.99524 10.875 2.99524C6.52576 2.99524 3 6.521 3 10.8702C3 15.2195 6.52576 18.7452 10.875 18.7452Z',
                'stroke': '#2E6DA4',
                'strokeWidth': '2',
                'strokeLinecap': 'round',
                'strokeLinejoin': 'round'
            }), _createElement('path', {
                'd': 'M16.4438 16.4438L21.0001 21',
                'stroke': '#2E6DA4',
                'strokeWidth': '2',
                'strokeLinecap': 'round',
                'strokeLinejoin': 'round'
            }))]),
        _createElement('span', {
            'className': 'cm_search-box_clear-container',
            'key': '1304'
        }, this.inputNotEmpty ? _createElement('span', {
            'className': 'cm_search-box_clear',
            'onClick': this.clearInput,
            'key': '1211'
        }, window.innerWidth > 1366 ? ['Clear'] : null, window.innerWidth <= 1366 ? [_createElement('svg', {
                'width': '18',
                'height': '18',
                'viewBox': '0 0 18 18',
                'fill': 'none',
                'xmlns': 'http://www.w3.org/2000/svg',
                'key': '13760'
            }, _createElement('path', {
                'd': 'M18 2.28077L15.7192 0L8.99946 6.71977L2.28077 0L0 2.28077L6.71977 8.99946L0 15.7192L2.28077 18L8.99946 11.2802L15.7192 18L18 15.7192L11.2802 8.99946L18 2.28077Z',
                'fill': '#2E6DA4'
            }))] : null) : null),
        _createElement('div', {
            'className': 'search__predictive__close',
            'key': '1306'
        }, _createElement('button', {
            'className': 'search__predictive__close__inner',
            'data-close-popdown': true,
            'aria-label': 'Close',
            'onClick': () => window.Convermax.closeSearchBoxPopup()
        }, [_createElement('svg', {
                'width': '18',
                'height': '18',
                'viewBox': '0 0 18 18',
                'fill': 'none',
                'xmlns': 'http://www.w3.org/2000/svg',
                'key': '19470'
            }, _createElement('path', {
                'd': 'M18 2.28077L15.7192 0L8.99946 6.71977L2.28077 0L0 2.28077L6.71977 8.99946L0 15.7192L2.28077 18L8.99946 11.2802L15.7192 18L18 15.7192L11.2802 8.99946L18 2.28077Z',
                'fill': '#2E6DA4'
            }))]))
    ]), [this.dropdown(function () {
            function repeatSections1(hasProductResults, sections, sectionsIndex) {
                return [sections(function () {
                        function repeatItems1(isProducts, items, itemsIndex) {
                            return [items(function () {
                                    function mergeProps_text(inline, external) {
                                        var res = Object.assign({}, inline, external);
                                        if (inline.hasOwnProperty('style')) {
                                            res.style = _.defaults(res.style, inline.style);
                                        }
                                        if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                            res.className = external.className + ' ' + inline.className;
                                        }
                                        return res;
                                    }
                                    function mergeProps_product(inline, external) {
                                        var res = Object.assign({}, inline, external);
                                        if (inline.hasOwnProperty('style')) {
                                            res.style = _.defaults(res.style, inline.style);
                                        }
                                        if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                            res.className = external.className + ' ' + inline.className;
                                        }
                                        return res;
                                    }
                                    return this.template === 'text' ? _createElement('div', mergeProps_text({ 'className': (this.text === this.query ? 'active' : '') + ' cmTemplate_text' }, { dangerouslySetInnerHTML: { __html: this.textHighlight } })) : this.template === 'product' ? _createElement('div', { 'className': 'cmTemplate_product' }, _createElement('a', {
                                        'className': 'dropdrown-product-img',
                                        'href': this.url
                                    }, _createElement('img', {
                                        'src': this.imageOrDefault(this.image),
                                        'alt': this.removeHTML(this.title),
                                        'onError': this.onImageError
                                    })), _createElement('div', { 'className': 'dropdrown-product-title' }, _createElement('div', { 'className': 'sku' }, 'SKU# ', this.removeHTML(this.sku)), _createElement('a', mergeProps_product({
                                        'className': 'h6',
                                        'href': this.url
                                    }, { dangerouslySetInnerHTML: { __html: this.title } })))) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                                }, { count: undefined })];
                        }
                        function scopeIsProducts2() {
                            var isProducts = this.sectionTitle?.includes('Products');
                            return _createElement('div', {
                                'className': 'section' + (isProducts ? ' products' : hasProductResults ? ' queries' : ''),
                                'key': '3'
                            }, _createElement('div', { 'className': 'section-title-div' }, _createElement('div', { 'className': 'item section-title' }, '\n        ', isProducts ? 'suggested products' : 'Popular Keywords', !!this.vehicleString ? ` for
        ${ this.vehicleString }` : '', '\n      ')), _createElement.apply(this, [
                                'div',
                                { 'className': 'cmRepeater_items' },
                                _map(this.items, repeatItems1.bind(this, isProducts))
                            ]), isProducts ? [[this.browseAllButton(function () {
                                        return _createElement('button', { 'className': 'item button-item cm_browseAllButton' }, '\n        see all ', _createElement('b', {}, this.totalHits), ' results\n      ');
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })]] : null);
                        }
                        return this.items.length ? scopeIsProducts2.apply(this, []) : null;
                    }, { count: undefined })];
            }
            function scopeHasProductResults2() {
                var hasProductResults = this.hasProductResults;
                return _createElement.apply(this, [
                    'div',
                    { 'className': 'cmRepeater_sections' },
                    _map(this.sections, repeatSections1.bind(this, hasProductResults))
                ]);
            }
            return _createElement('div', { 'className': 'cm_autocomplete cm_dropdown' }, scopeHasProductResults2.apply(this, []));
        }, {
            widgetName: 'undefined',
            items: undefined
        })]);
}
        export const componentNames = ["cm:searchInput","cm:browseAllButton","cm:dropdown"]