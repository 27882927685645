
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function FacetDialogRT () {
    return _createElement('div', { 'className': 'dialog-container' }, _createElement('div', { 'className': 'dialog-content' }, _createElement('div', { 'className': 'dialog-header' }, _createElement('div', { 'className': 'dialog-title-container' }, _createElement('div', { 'className': 'dialog-title' }, 'Filters')), _createElement('div', { 'className': 'buttons-container' }, _createElement('div', {
        'className': 'button-clear btn btn--secondary',
        'onClick': this.clear
    }, 'Clear All'), _createElement('div', {
        'className': 'button-close',
        'onClick': this.close
    }, _createElement('svg', {
        'width': '24',
        'height': '24',
        'viewBox': '0 0 24 24',
        'fill': 'none',
        'xmlns': 'http://www.w3.org/2000/svg'
    }, _createElement('path', {
        'd': 'M24 3.04102L20.959 0L11.9993 8.95969L3.04102 0L0 3.04102L8.95969 11.9993L0 20.959L3.04102 24L11.9993 15.0403L20.959 24L24 20.959L15.0403 11.9993L24 3.04102Z',
        'fill': '#0A0E14'
    }))))), _createElement('div', { 'className': 'cm_FacetPanel cm_facet-panel__full-width dialog-body' }, [[this.filterChips(function () {
                function repeatChips1(chips, chipsIndex) {
                    return [chips(function () {
                            return this.template === 'chip' ? _createElement('div', { 'className': (this.name !== 'query' ? 'filter' : '') + ' cmTemplate_chip' }, this.name === 'query' ? _createElement('div', {
                                'className': 'chipstitle',
                                'key': '110'
                            }, 'Keyword Search') : null, this.name !== 'query' ? _createElement('div', {
                                'className': 'chipstitle',
                                'key': '205'
                            }, 'active filters') : null, _createElement('div', { 'className': 'facetdiv' }, _createElement('a', {
                                'className': 'facetentrykey',
                                'tabIndex': '0',
                                'role': 'listitem'
                            }, ' ', this.value, ' '), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                    'className': 'cm_icon cm_icon-times',
                                    'height': '20px',
                                    'role': 'img',
                                    'viewBox': '0 0 22 22',
                                    'key': '4470'
                                }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))]))) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                        }, { count: undefined })];
                }
                return _createElement('div', { 'className': 'cm_filterChips' }, _createElement('div', { 'className': 'facetholder' }, _createElement('div', { 'className': 'facetbody' }, _createElement.apply(this, [
                    'div',
                    {
                        'role': 'list',
                        'className': 'cmRepeater_chips'
                    },
                    _map(this.chips, repeatChips1.bind(this))
                ]), _createElement('div', {
                    'className': 'btn btn--secondary',
                    'data-cm-role': 'start-over'
                }, 'clear all'))));
            }, {
                widgetName: 'filter-chips',
                items: undefined
            })]], [this.facets(function () {
            function repeatFacets1(facets, facetsIndex) {
                return [facets(function () {
                        function repeatShowAlwaysValues1(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                            return [ShowAlwaysValues(function () {
                                    return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                        'type': 'checkbox',
                                        'className': 'cm_facet_checkbox',
                                        'readOnly': true,
                                        'checked': this.isSelected
                                    }), _createElement('a', {
                                        'className': 'facetentrykey',
                                        'tabIndex': '0',
                                        'role': 'listitem'
                                    }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                }, { count: undefined })];
                        }
                        function repeatValues2(Values, ValuesIndex) {
                            return [Values(function () {
                                    return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                        'type': 'checkbox',
                                        'className': 'cm_facet_checkbox',
                                        'readOnly': true,
                                        'checked': this.isSelected
                                    }), _createElement('a', {
                                        'className': 'facetentrykey',
                                        'tabIndex': '0',
                                        'role': 'listitem'
                                    }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                }, { count: undefined })];
                        }
                        function repeatValues3(Values, ValuesIndex) {
                            return [Values(function () {
                                    return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                        'type': 'checkbox',
                                        'className': 'cm_facet_checkbox',
                                        'readOnly': true,
                                        'checked': this.isSelected
                                    }), !this.isCustomRange ? [
                                        _createElement('a', {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'role': 'listitem',
                                            'key': '1541'
                                        }, _createElement('span', {}, this.value)),
                                        _createElement('div', {
                                            'className': 'facetentryval',
                                            'key': '1543'
                                        }, this.hitCount)
                                    ] : null, this.isCustomRange ? [_createElement('a', {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'role': 'listitem',
                                            'key': '3901'
                                        }, this.finite ? _createElement('span', { 'key': '503' }, this.range[0], ' - ', this.range[1]) : null, this.toPosInf ? _createElement('span', { 'key': '580' }, this.range[0], ' and more') : null, this.toNegInf ? _createElement('span', { 'key': '650' }, this.range[1], ' and less') : null)] : null);
                                }, { count: undefined })];
                        }
                        function scopeInchMm4() {
                            var inch = [].includes(this.field) ? '"' : '';
                            var mm = [
                                'wheel_bore',
                                'wheel_offset'
                            ].includes(this.field) ? 'mm' : '';
                            return _createElement('div', {
                                'className': 'cmTemplate_sliderFacet',
                                'key': '12401'
                            }, _createElement('div', { 'className': 'facetslider' }, _createElement('div', { 'className': 'facetsliderinfo' }, '\n    Selected: ', this.selectedRange[0], inch, mm, ' to ', this.selectedRange[1], inch, mm, '\n  '), '\n  ', this.slider, '\n  ', _createElement('div', { 'className': 'cm_slider-bounds' }, _createElement('div', { 'className': 'min' }, this.min, inch, mm), _createElement('div', { 'className': 'max' }, this.max, inch, mm), _createElement('div', { 'className': 'clear' }))), [this.Inputs(function () {
                                    return _createElement('div', { 'className': 'input cm_Inputs' }, this.inputMin ? [[this.inputMin(function () {
                                                return _createElement('div', {
                                                    'placeholder': 'Min',
                                                    'className': 'cm_inputMin'
                                                });
                                            }, {
                                                widgetName: 'undefined',
                                                items: undefined
                                            })]] : null, this.inputMin && this.inputMax ? _createElement('span', {
                                        'className': 'separator',
                                        'key': '334'
                                    }, '\u2014') : null, this.inputMax ? [[this.inputMax(function () {
                                                return _createElement('div', {
                                                    'placeholder': 'Max',
                                                    'className': 'cm_inputMax'
                                                });
                                            }, {
                                                widgetName: 'undefined',
                                                items: undefined
                                            })]] : null, _createElement('button', {
                                        'type': 'button',
                                        'className': 'cm_btn btn btn--secondary',
                                        'onClick': this.setCustomRange
                                    }, '\n    GO\n  '));
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })]);
                        }
                        function repeatShowAlwaysValues5(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                            return [ShowAlwaysValues(function () {
                                    function scopeStarFill1(i, iIndex) {
                                        var starFill = this.value - i;
                                        return _createElement('span', {
                                            'key': i,
                                            'className': 'cm_star cm_star__' + (starFill < 0.25 ? 'empty' : starFill < 0.75 ? 'half' : 'full')
                                        });
                                    }
                                    function repeatI2(i, iIndex, starFill) {
                                        return scopeStarFill1.apply(this, [
                                            i,
                                            iIndex
                                        ]);
                                    }
                                    return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                        'type': 'checkbox',
                                        'className': 'cm_facet_checkbox',
                                        'readOnly': true,
                                        'checked': this.isSelected
                                    }), _createElement('a', {
                                        'className': 'facetentrykey',
                                        'tabIndex': '0',
                                        'role': 'listitem'
                                    }, _createElement.apply(this, [
                                        'span',
                                        { 'className': 'cm_review-stars' },
                                        _map([
                                            0,
                                            1,
                                            2,
                                            3,
                                            4
                                        ], repeatI2.bind(this))
                                    ]), this.value !== '5' ? _createElement('span', {
                                        'className': 'cm_and-up',
                                        'key': '499'
                                    }, ' and up') : null), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                }, { count: undefined })];
                        }
                        function repeatValues6(Values, ValuesIndex) {
                            return [Values(function () {
                                    function scopeStarFill1(i, iIndex) {
                                        var starFill = this.value - i;
                                        return _createElement('span', {
                                            'key': i,
                                            'className': 'cm_star cm_star__' + (starFill < 0.25 ? 'empty' : starFill < 0.75 ? 'half' : 'full')
                                        });
                                    }
                                    function repeatI2(i, iIndex, starFill) {
                                        return scopeStarFill1.apply(this, [
                                            i,
                                            iIndex
                                        ]);
                                    }
                                    return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                        'type': 'checkbox',
                                        'className': 'cm_facet_checkbox',
                                        'readOnly': true,
                                        'checked': this.isSelected
                                    }), _createElement('a', {
                                        'className': 'facetentrykey',
                                        'tabIndex': '0',
                                        'role': 'listitem'
                                    }, _createElement.apply(this, [
                                        'span',
                                        { 'className': 'cm_review-stars' },
                                        _map([
                                            0,
                                            1,
                                            2,
                                            3,
                                            4
                                        ], repeatI2.bind(this))
                                    ]), this.value !== '5' ? _createElement('span', {
                                        'className': 'cm_and-up',
                                        'key': '499'
                                    }, ' and up') : null), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                }, { count: undefined })];
                        }
                        return _createElement('div', { 'className': 'facetholder cm_field-' + this.field }, this.template !== 'toggleFacet' ? [
                            _createElement('div', {
                                'className': 'facettitle',
                                'data-cm-role': 'toggle-facet',
                                'tabIndex': '0',
                                'key': '561'
                            }, _createElement('span', {}, this.name), _createElement('div', { 'className': !this.isCollapsed ? 'chevron-down' : '' }, _createElement('svg', {
                                'width': '12',
                                'height': '8',
                                'viewBox': '0 0 12 8',
                                'fill': 'none',
                                'xmlns': 'http://www.w3.org/2000/svg'
                            }, _createElement('path', {
                                'd': 'M1.41 7.71875L6 3.12151L10.59 7.71875L12 6.30344L6 0.280858L0 6.30344L1.41 7.71875Z',
                                'fill': '#0A0E14'
                            })))),
                            _createElement('div', {
                                'className': 'facetbody' + (this.template === 'simpleFacet' && this.needShowMore && !this.moreValuesToShow ? ' expanded' : ''),
                                'key': '563'
                            }, this.template === 'simpleFacet' ? _createElement('div', {
                                'role': 'list',
                                'className': 'cmTemplate_simpleFacet',
                                'key': '730'
                            }, this.showFilterInput ? _createElement('div', {
                                'className': 'filter-input',
                                'key': '828'
                            }, [this.filterInput(function () {
                                    return _createElement('div', {
                                        'className': 'input  cm_filterInput',
                                        'placeholder': 'Enter'
                                    });
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })], this.inputNotEmpty ? _createElement('span', {
                                'className': 'filter-input_clear-container',
                                'onClick': this.clearInput,
                                'key': '1148'
                            }, _createElement('span', { 'className': 'filter-input_clear' }, '\u2715')) : null) : null, !this.ShowAlwaysValues.length && !this.Values.length ? [_createElement('div', {
                                    'className': 'facetdiv',
                                    'key': '13171'
                                }, _createElement('span', { 'className': 'facetentrykey' }, 'No entries found'))] : null, _createElement('div', { 'className': 'facetvalues' + (this.showFilterInput && !this.moreValuesToShow ? ' overflowed' : '') }, _createElement.apply(this, [
                                'div',
                                { 'className': 'cmRepeater_ShowAlwaysValues' },
                                _map(this.ShowAlwaysValues, repeatShowAlwaysValues1.bind(this))
                            ]), _createElement.apply(this, [
                                'div',
                                { 'className': 'cmRepeater_Values' },
                                _map(this.Values, repeatValues2.bind(this))
                            ]), this.needShowMore ? _createElement('div', {
                                'className': 'facetdiv cm_show-all-container',
                                'key': '3148'
                            }, _createElement('a', {
                                'className': 'cm_show-all',
                                'data-cm-role': 'toggle-show-more',
                                'tabIndex': '0'
                            }, this.moreValuesToShow ? ['Show more'] : null, !this.moreValuesToShow ? ['Show less'] : null)) : null)) : null, this.template === 'selectFacet' ? _createElement('div', {
                                'className': 'cmTemplate_selectFacet',
                                'key': '3476'
                            }, [this.select(function () {
                                    function repeatEntry1(entry, entryIndex) {
                                        return _createElement('option', {
                                            'disabled': this.loading,
                                            'value': entry.term,
                                            'key': entry.value
                                        }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                    }
                                    function repeatEntry2(entry, entryIndex) {
                                        return _createElement('option', {
                                            'disabled': this.loading,
                                            'value': entry.term,
                                            'key': entry.value
                                        }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                    }
                                    function repeatEntry3(entry, entryIndex) {
                                        return _createElement('option', {
                                            'disabled': this.loading,
                                            'value': entry.term,
                                            'key': entry.value
                                        }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                    }
                                    function repeatEntry4(entry, index) {
                                        return _createElement('div', {
                                            'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                            'key': entry.value,
                                            'onClick': () => this.onChange(entry.term)
                                        }, this.showCheckboxes ? _createElement('input', {
                                            'type': 'checkbox',
                                            'readOnly': true,
                                            'checked': entry.selected,
                                            'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                            'key': '3596'
                                        }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                            'className': 'cm_dropdown_hitCount',
                                            'key': '3798'
                                        }, entry.hitCount) : null);
                                    }
                                    return _createElement('div', { 'className': 'cm_select' }, _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                                        'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                                        'key': '62'
                                    }, _createElement('select', {
                                        'className': 'cm_select_inner-select cm_select__pretty',
                                        'onChange': this.onChange,
                                        'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                        'disabled': this.disabled,
                                        'aria-label': this.title
                                    }, this.useNativeDropdown ? [
                                        !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                            'value': '',
                                            'key': 'null-option'
                                        }, '\n          ', '', this.title, '\n        ') : null,
                                        this.loading ? _createElement('option', {
                                            'key': 'loading-option',
                                            'disabled': true
                                        }, '...loading...') : null,
                                        this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                                            _createElement('option', {
                                                'className': 'cm_option_title',
                                                'disabled': true,
                                                'key': '8031'
                                            }, 'Popular Makes'),
                                            _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                                            _createElement('option', {
                                                'className': 'cm_option_title',
                                                'disabled': true,
                                                'key': '8034'
                                            }, 'All Makes'),
                                            _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                                        ] : null,
                                        this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                                    ] : null, !this.useNativeDropdown ? _createElement('option', {
                                        'key': '_current',
                                        'value': '_current'
                                    }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' } ${ this.title }`, '\n      ') : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                                return _createElement('div', { 'className': 'cm_filterInput' });
                                            }, {
                                                widgetName: 'undefined',
                                                items: undefined
                                            })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                        'div',
                                        {
                                            'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                            'aria-label': this.title,
                                            'key': '2892'
                                        },
                                        !this.hideNullOption ? _createElement('div', {
                                            'className': 'option',
                                            'key': 'null-option',
                                            'onClick': () => this.onChange('')
                                        }, '\n      ', '', this.title, '\n    ') : null,
                                        this.loading ? _createElement('div', {
                                            'className': 'option',
                                            'key': 'null-option',
                                            'disabled': true
                                        }, '...loading...') : null,
                                        !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                                    ]) : null));
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })]) : null, this.template === 'priceFacet' ? _createElement('div', {
                                'role': 'list',
                                'className': 'cmTemplate_priceFacet',
                                'key': '9458'
                            }, !this.ranges.length ? [
                                _createElement('div', { 'key': '95541' }, this.selectedRawRange[0], ' to ', this.selectedRawRange[1]),
                                '\n  ',
                                this.slider,
                                '\n  ',
                                _createElement('div', {
                                    'className': 'cm_flex',
                                    'key': '95543'
                                }, _createElement('div', { 'className': 'cm_flex-grow' }, 'Min: ', this.rawMin), _createElement('div', {}, 'Max: ', this.rawMax))
                            ] : null, this.ranges.length ? [_createElement.apply(this, [
                                    'div',
                                    {
                                        'className': 'cmRepeater_Values',
                                        'key': '98181'
                                    },
                                    _map(this.Values, repeatValues3.bind(this))
                                ])] : null, [this.Inputs(function () {
                                    return _createElement('div', { 'className': 'input cm_Inputs' }, [this.inputMin(function () {
                                            return _createElement('div', {
                                                'placeholder': 'Min price',
                                                'className': 'cm_inputMin'
                                            });
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })], _createElement('span', { 'className': 'separator' }, '\u2014'), [this.inputMax(function () {
                                            return _createElement('div', {
                                                'placeholder': 'Max price',
                                                'className': 'cm_inputMax'
                                            });
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })], _createElement('button', {
                                        'type': 'button',
                                        'className': 'cm_btn btn btn--secondary',
                                        'onClick': this.setCustomRange
                                    }, '\n    GO\n  '));
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })]) : null, this.template === 'sliderFacet' ? scopeInchMm4.apply(this, []) : null, this.template === 'reviewFacet' ? _createElement('div', {
                                'role': 'list',
                                'className': 'cmTemplate_reviewFacet',
                                'key': '14158'
                            }, _createElement.apply(this, [
                                'div',
                                { 'className': 'cmRepeater_ShowAlwaysValues' },
                                _map(this.ShowAlwaysValues, repeatShowAlwaysValues5.bind(this))
                            ]), _createElement.apply(this, [
                                'div',
                                { 'className': 'cmRepeater_Values' },
                                _map(this.Values, repeatValues6.bind(this))
                            ])) : null)
                        ] : null, this.template === 'toggleFacet' ? _createElement('div', {
                            'className': 'cmTemplate_toggleFacet',
                            'key': '17318'
                        }, _createElement('div', { 'className': 'facettitle cm_facet-toggle' }, _createElement('span', {}, this.name), _createElement('label', { 'className': 'cm_facet-toggle_switch' }, _createElement('input', {
                            'className': 'cm_facet-toggle_input',
                            'type': 'checkbox',
                            'checked': this.isToggled,
                            'onClick': this.toggleFacet
                        }), _createElement('span', { 'className': 'cm_facet-toggle_slider' })))) : null);
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'cm_facets' }, _createElement.apply(this, [
                'div',
                { 'className': 'cmRepeater_facets' },
                _map(this.facets, repeatFacets1.bind(this))
            ]));
        }, {
            widgetName: 'undefined',
            items: undefined
        })])));
}
        export const componentNames = ["cm:filterChips","cm:filterInput","cm:filterInput","cm:select","cm:inputMin","cm:inputMax","cm:Inputs","cm:inputMin","cm:inputMax","cm:Inputs","cm:facets"]