
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function heroVehicleWidgetRT () {
    function repeatSelects1(selects, selectsIndex) {
        return [selects(function () {
                function repeatEntry1(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n        ', entry.value, '\n      ');
                }
                return _createElement('div', { 'className': this.extraClassName }, _createElement('div', { 'className': 'cm_select_toggle cm_select_toggle__native cm_toggle-dropdown' }, this.index || this.index === 0 ? _createElement('span', {
                    'className': 'index' + (this.disabled ? ' disabled' : ''),
                    'key': '117'
                }, '\n      ', this.index + 1, '\n    ') : null, _createElement.apply(this, [
                    'select',
                    {
                        'className': 'cm_select_inner-select cm_select__pretty',
                        'onChange': this.onChange,
                        'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                        'disabled': this.disabled
                    },
                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                        'value': '',
                        'key': 'null-option'
                    }, '\n        ', this.title, '\n      ') : null,
                    this.loading ? _createElement('option', {
                        'key': 'loading-option',
                        'disabled': true
                    }, '...loading...') : null,
                    _map(this.entries, repeatEntry1.bind(this))
                ]), _createElement('svg', {
                    'width': '15',
                    'height': '10',
                    'viewBox': '0 0 15 10',
                    'fill': 'none',
                    'xmlns': 'http://www.w3.org/2000/svg'
                }, _createElement('path', {
                    'd': 'M13 2L7.375 8L1.75 2',
                    'stroke': '#0A0E14',
                    'strokeWidth': '2.5',
                    'strokeLinecap': 'round',
                    'strokeLinejoin': 'round'
                }))));
            }, { count: undefined })];
    }
    function repeatField2(field, fieldIndex) {
        return [_createElement('p', { 'key': '32471' }, this.vehicle[field], fieldIndex < 2 ? [' '] : null)];
    }
    function repeatField3(field, fieldIndex) {
        return [this.vehicle[field] ? _createElement('p', { 'key': '34531' }, ' | ', this.vehicle[field]) : null];
    }
    return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__search' }, this.template === 'active' ? _createElement('div', {
        'className': 'cm_vehicle-widget_dropdowns cmTemplate_active',
        'key': '60'
    }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, _createElement('div', { 'className': 'text' }, 'select your vehicle'), _createElement('div', { 'className': 'line' })), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
        'div',
        { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
        _map(this.selects, repeatSelects1.bind(this))
    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go btn btn--secondary',
        'onClick': this.selectVehicle,
        'disabled': !this.allowToSetVehicle
    }, '\n    search\n  '), _createElement('div', {
        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear',
        'onClick': this.discardVehicle,
        'disabled': !this.allowToDiscardVehicle
    }, '\n    reset\n  ')))) : null, this.template === 'locked' ? _createElement('div', {
        'className': 'cm_vehicle-widget_label cmTemplate_locked',
        'key': '2991'
    }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, _createElement('span', { 'className': 'cm_vehicle-widget_subtitle' }, 'Your Selected Vehicle'), _createElement.apply(this, [
        'div',
        { 'className': 'cm_vehicle-widget_vehicle' },
        _map([
            'Year',
            'Make',
            'Model'
        ], repeatField2.bind(this)),
        _map([
            'Drive Wheel',
            'More Info'
        ], repeatField3.bind(this))
    ])), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go btn btn--secondary',
        'onClick': this.discardVehicle
    }, '\n      clear vehicle selection\n    ')))) : null);
}
        export const componentNames = []