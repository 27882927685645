
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function garageRT () {
    return _createElement('a', { 'className': 'cm_garage navlink' + (!this.isVehicleSelected ? ' empty' : '') }, this.name === 'VerifyFitmentGarage' ? _createElement('div', {
        'className': 'garage-info',
        'key': '93'
    }, [_createElement('svg', {
            'width': '24',
            'height': '24',
            'viewBox': '0 0 24 24',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'key': '1770'
        }, _createElement('path', {
            'd': 'M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z',
            'stroke': '#C69E25',
            'strokeWidth': '2',
            'strokeLinecap': 'round',
            'strokeLinejoin': 'round'
        }), _createElement('path', {
            'd': 'M12 18C12.6213 18 13.125 17.4963 13.125 16.875C13.125 16.2537 12.6213 15.75 12 15.75C11.3787 15.75 10.875 16.2537 10.875 16.875C10.875 17.4963 11.3787 18 12 18Z',
            'fill': '#C69E25'
        }), _createElement('line', {
            'x1': '11.75',
            'y1': '13.25',
            'x2': '11.75',
            'y2': '7.75',
            'stroke': '#C69E25',
            'strokeWidth': '2',
            'strokeLinecap': 'round'
        }))], '\n    Please add or select vehicle to verify fitment\n  ') : null, _createElement('div', { 'className': 'garage-toggle-container' }, _createElement('div', { 'className': 'cm_toggle-dropdown' }, this.name === 'VerifyFitmentGarage' ? _createElement('div', {
        'className': 'garage-button btn btn--secondary',
        'onClick': this.showVehicleDropdowns,
        'key': '954'
    }, '\n        ADD A VEHICLE TO YOUR GARAGE\n      ') : null, _createElement('div', {
        'className': 'garage-button',
        'title': 'Garage: ' + this.size + ' item(s)'
    }, _createElement('div', { 'className': 'garage-icon-container' }, [_createElement('svg', {
            'width': '25',
            'height': '21',
            'viewBox': '0 0 25 21',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'key': '12520'
        }, _createElement('path', {
            'd': 'M9.5 13.7451H15.5V16.2316H9.5V13.7451Z',
            'fill': 'white'
        }), _createElement('path', {
            'd': 'M21.4569 6.47743C21.5153 6.6963 21.7135 6.84858 21.94 6.84858H24V7.94166H22.5C22.2239 7.94166 22 8.16552 22 8.44166V15.767C21.9996 16.1966 21.8361 16.6042 21.5521 16.9014C21.2689 17.1979 20.8898 17.3601 20.4995 17.3605C20.2235 17.3608 20 17.5846 20 17.8605V20.5002H19V17.8605C19 17.5844 18.7761 17.3605 18.5 17.3605H6.5C6.22386 17.3605 6 17.5844 6 17.8605V20.5002H5V17.8605C5 17.5846 4.77646 17.3608 4.50052 17.3605C4.1102 17.3601 3.73112 17.1979 3.44787 16.9014C3.16394 16.6043 3.00047 16.1968 3 15.7672C3 15.7671 3 15.767 3 15.767L3 8.44166C3 8.16552 2.77614 7.94166 2.5 7.94166H1V6.84858H3.031C3.25643 6.84858 3.45398 6.69773 3.51335 6.48027L4.81288 1.7202C4.81296 1.71993 4.81303 1.71965 4.81311 1.71937C4.90641 1.38296 5.10014 1.09133 5.36087 0.885586C5.62119 0.680164 5.93489 0.570628 6.25493 0.569336H18.7452C19.0654 0.570435 19.3793 0.679893 19.6398 0.885351C19.9004 1.09085 20.0941 1.38211 20.1875 1.71813C20.1877 1.71882 20.1879 1.71951 20.1881 1.72021L21.4569 6.47743ZM6.255 1.66242C6.02959 1.66242 5.83205 1.81325 5.77266 2.0307L4.34366 7.2634C4.3026 7.41376 4.33401 7.57467 4.4286 7.69855C4.52318 7.82244 4.67013 7.89512 4.826 7.89512H20.175C20.3308 7.89512 20.4778 7.82245 20.5724 7.69859C20.667 7.57472 20.6984 7.41384 20.6574 7.26349L19.2294 2.03078C19.17 1.81329 18.9724 1.66242 18.747 1.66242H6.255ZM21 13.6744C21 13.3982 20.7761 13.1744 20.5 13.1744H19V12.0813H20.5C20.7761 12.0813 21 11.8574 21 11.5813V9.4882C21 9.21206 20.7761 8.9882 20.5 8.9882H4.5C4.22386 8.9882 4 9.21206 4 9.4882V11.5813C4 11.8574 4.22386 12.0813 4.5 12.0813H6V13.1744H4.5C4.22386 13.1744 4 13.3982 4 13.6744V15.7674C4 16.0436 4.22386 16.2674 4.5 16.2674H20.5C20.7761 16.2674 21 16.0436 21 15.7674V13.6744Z',
            'fill': 'white',
            'stroke': 'white',
            'strokeLinejoin': 'round'
        }))], this.size ? [_createElement('span', {
            'className': 'garage-size',
            'key': 'garage-size'
        }, this.size)] : null), _createElement('span', { 'className': 'garage-label' }, this.name === 'HeroGarage' || this.name === 'VerifyFitmentGarage' ? 'view your garage' : 'garage'))), [this.garageDropdown(function () {
            function repeatItems1(items, itemsIndex) {
                return [items(function () {
                        return _createElement('div', { 'className': 'garage-item' }, _createElement('span', {
                            'className': 'select-model' + (this.isSelected ? ' selected' : ''),
                            'data-cm-role': 'select-vehicle'
                        }, '\n          ', this.vehicleString, '\n        '), _createElement('span', {
                            'className': 'remove-model',
                            'data-cm-role': 'remove-vehicle'
                        }, [_createElement('svg', {
                                'className': 'cm_icon cm_icon-times',
                                'height': '20px',
                                'role': 'img',
                                'viewBox': '0 0 22 22',
                                'key': '2670'
                            }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))]));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'cm_garageDropdown' }, this.template === 'list' ? _createElement('div', {
                'className': 'cmTemplate_list',
                'key': '31'
            }, _createElement('div', { 'className': 'garage-title' }, _createElement('div', { 'className': 'text' }, 'Your Garage'), _createElement('div', { 'className': 'line' })), _createElement('div', { 'className': 'garage-content' }, _createElement.apply(this, [
                'div',
                { 'className': 'cmRepeater_items' },
                _map(this.items, repeatItems1.bind(this))
            ]), _createElement('div', { 'className': 'garage-button-container' }, _createElement('div', {
                'className': 'add-vehicle  btn btn--secondary',
                'data-cm-role': 'show-vehicle-dropdowns'
            }, ['add more vehicles']), _createElement('div', {
                'className': 'clear-garage',
                'data-cm-role': 'clear-garage'
            }, 'Clear garage')))) : null, this.template === 'empty' ? _createElement('div', {
                'className': 'cmTemplate_empty',
                'key': '1525'
            }, _createElement('div', { 'className': 'garage-title' }, _createElement('div', { 'className': 'text' }, 'Your Garage'), _createElement('div', { 'className': 'line' })), _createElement('div', { 'className': 'garage-content' }, _createElement('ul', {}, _createElement('li', { 'className': 'vehicle-advantages' }, 'Your virtual garage is empty.'), _createElement('li', { 'className': 'vehicle-advantages' }, '\n        Saving your vehicle details makes it easier to find products that fit\n      '), _createElement('li', { 'className': 'vehicle-advantages' }, 'You can save multiple vehicles, and swap between them anytime')), _createElement('div', {
                'className': 'add-vehicle  btn btn--secondary',
                'data-cm-role': 'show-vehicle-dropdowns'
            }, ['add more vehicles']))) : null, this.template === 'vehicleDropdowns' ? _createElement('div', {
                'className': 'cmTemplate_vehicleDropdowns',
                'key': '2220'
            }, [this.vehicleWidget(function () {
                    function repeatSelects1(selects, selectsIndex) {
                        return [selects(function () {
                                function repeatEntry1(entry, entryIndex) {
                                    return _createElement('option', {
                                        'disabled': this.loading,
                                        'value': entry.term,
                                        'key': entry.value
                                    }, '\n        ', entry.value, '\n      ');
                                }
                                return _createElement('div', { 'className': this.extraClassName }, _createElement('div', { 'className': 'cm_select_toggle cm_select_toggle__native cm_toggle-dropdown' }, this.index || this.index === 0 ? _createElement('span', {
                                    'className': 'index' + (this.disabled ? ' disabled' : ''),
                                    'key': '117'
                                }, '\n      ', this.index + 1, '\n    ') : null, _createElement.apply(this, [
                                    'select',
                                    {
                                        'className': 'cm_select_inner-select cm_select__pretty',
                                        'onChange': this.onChange,
                                        'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                        'disabled': this.disabled
                                    },
                                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                        'value': '',
                                        'key': 'null-option'
                                    }, '\n        ', this.title, '\n      ') : null,
                                    this.loading ? _createElement('option', {
                                        'key': 'loading-option',
                                        'disabled': true
                                    }, '...loading...') : null,
                                    _map(this.entries, repeatEntry1.bind(this))
                                ]), _createElement('svg', {
                                    'width': '15',
                                    'height': '10',
                                    'viewBox': '0 0 15 10',
                                    'fill': 'none',
                                    'xmlns': 'http://www.w3.org/2000/svg'
                                }, _createElement('path', {
                                    'd': 'M13 2L7.375 8L1.75 2',
                                    'stroke': '#0A0E14',
                                    'strokeWidth': '2.5',
                                    'strokeLinecap': 'round',
                                    'strokeLinejoin': 'round'
                                }))));
                            }, { count: undefined })];
                    }
                    return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__garage cm_vehicleWidget' }, this.template === 'active' ? _createElement('div', {
                        'className': 'cm_vehicle-widget_dropdowns cmTemplate_active',
                        'key': '79'
                    }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, _createElement('div', { 'className': 'text' }, 'select your vehicle'), _createElement('div', { 'className': 'line' })), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
                        _map(this.selects, repeatSelects1.bind(this))
                    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go btn btn--secondary',
                        'onClick': this.selectVehicle,
                        'disabled': !this.allowToSetVehicle
                    }, '\n            save this vehicle\n          '), _createElement('div', {
                        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear',
                        'onClick': this.discardVehicle,
                        'disabled': !this.allowToDiscardVehicle
                    }, '\n            reset\n          ')))) : null);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]) : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })]));
}
        export const componentNames = ["cm:vehicleWidget","cm:garageDropdown"]